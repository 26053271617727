import revive_payload_client_3JN7QgYchO from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.1_rxekkteupnybdapdd26htq4thq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CVxL9y3PBM from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.1_rxekkteupnybdapdd26htq4thq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aRWAOR3RZk from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.1_rxekkteupnybdapdd26htq4thq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4AuqtDMYwg from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.1_rxekkteupnybdapdd26htq4thq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_rCyHw7xTre from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.1_rxekkteupnybdapdd26htq4thq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NmyQQfB1hf from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.1_rxekkteupnybdapdd26htq4thq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ttk4opKx3f from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.1_rxekkteupnybdapdd26htq4thq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_8HvRfVbKi6 from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.1_rxekkteupnybdapdd26htq4thq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_iYCGKsovrQ from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_aJdW5ar5PC from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.4_rollup@4.18.0_vue@3.5.5_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_a9bzAwGQZi from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.4_rollup@4.18.0_vue@3.5.5_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
export default [
  revive_payload_client_3JN7QgYchO,
  unhead_CVxL9y3PBM,
  router_aRWAOR3RZk,
  payload_client_4AuqtDMYwg,
  navigation_repaint_client_rCyHw7xTre,
  check_outdated_build_client_NmyQQfB1hf,
  chunk_reload_client_ttk4opKx3f,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8HvRfVbKi6,
  plugin_client_iYCGKsovrQ,
  switch_locale_path_ssr_aJdW5ar5PC,
  i18n_a9bzAwGQZi
]